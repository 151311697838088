export const Menu = [
  {
    label: "Projects",
    url: "/projects",
  },
  {
    label: "Developers",
    url: "/developers",
  },
];
