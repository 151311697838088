import { devika, hanna } from "../assets/team/index";
const designers = [
  {
    id: 1,
    name: "Devika Ajith",
    image: devika,
  },
  {
    id: 2,
    name: "Hanna Ashraf",
    image: hanna,
  },
];

export default designers;
